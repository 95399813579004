import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Logo from "Images/logo.svg";
import "./styles.scss";
import { Visible } from "react-grid-system";

const Header = () => {
  const currentPage = "";
  const [isActive, setIsActive] = useState(false);

  const [showFullBackground, setShowFullBackground] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY >= 300;
      setShowFullBackground(show);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const isCurrentlySelectedItem = () => {
  //   // map urls to specific menu items
  // };

  const NavLink = ({ children, to = null, submenu }) => {
    return (
      <li className="nav-item">
        {to ? (
          <Link title={children} to={to} activeClassName="active">
            {children}
          </Link>
        ) : (
          <span>{children}</span>
        )}
        {submenu ? (
          <ul className="submenu">
            {submenu.map((item) => (
              <li>
                <Link title={item.label} to={item.to}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        ) : null}
      </li>
    );
  };

  return (
    <header
      className={`page-header ${showFullBackground ? "full-background" : ""}`}
    >
      <div className="_center page-header__content">
        <div className="page-header__controls--container">
          <Link title="Interservit" to="/" className="page-header__logo">
            <Logo />
          </Link>
          <Visible xs sm>
            <button
              className={`hamburger hamburger--arrowturn ${
                isActive ? "is-active" : ""
              }`}
              type="button"
              onClick={() => setIsActive(!isActive)}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </Visible>
        </div>
        <ul className={`page-nav ${isActive ? "is-active" : ""}`}>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/nosotros">Nosotros</NavLink>
          <NavLink
            submenu={[
              {
                label: "Blindajes 360",
                to: "/blindajes-360",
              },
              {
                label: "Blindaje Estratégico",
                to: "/blindaje-estrategico",
              },
            ]}
          >
            Blindados
          </NavLink>
          <NavLink
            submenu={[
              {
                label: "Industria de Defensa",
                to: "/industria-de-defensa",
              },
              {
                label: "Entidades bancarias",
                to: "/entidades-bancarias",
              },
            ]}
          >
            Servicios
          </NavLink>
          <NavLink to="#contacto">Contacto</NavLink>
        </ul>
      </div>
    </header>
  );
};

export default Header;
