import React from "react";
import { Helmet } from "react-helmet";
import Header from "Commons/Header";
import Footer from "Commons/Footer";
import './styles.scss';

// import { setConfiguration } from 'react-grid-system';
// setConfiguration({ maxScreenClass: 'xl' });

const PageWrapper = ({ children, dark = false }) => {
  return (
    <main className={dark ? 'dark-bg' : ''}>
      <Helmet>
        <title>
          Interservit
        </title>
      </Helmet>
      <Header />
      <section className="content">
        {children}
      </section>
      <Footer />
    </main>
  );
};

export default PageWrapper;
