import React, { useState } from "react";
import { Container, Row, Col } from 'react-grid-system';
import Arrow from 'Images/footer_arrow.svg';
import Number from 'Images/footer_number.svg';
import Logo from 'Images/logo.svg';
import { Link } from 'gatsby';
import './styles.scss';

import { useForm, ValidationError } from '@formspree/react';

const Input = ({ label, name, type, required }) => (
  <div className="form-input__container">
    <label htmlFor={name}>
      {label}
    </label>
    {type === 'textarea' ? (
      <textarea name={name} rows={2}></textarea>
    ) : (
      <input name={name} type={type || "text"} required={required} />
    )}
  </div>
)

const Footer = () => {
  const [state, handleSubmit] = useForm("mgerdnke");

  return (
    <footer className="page-footer">
      <section className="page-footer__contact" id="contacto">
        <Arrow className="page-footer__arrow" />
        <div className="_center">
          <Container>
            <Row>
              <Col sm={12}>
                <h2 className="_sub-heading">
                  <Number className="page-footer__number" />
                  Para más información,<br />contactanos.
                </h2>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <p className="accent-paragraph">Estamos para ayudarte. recibí la protección y seguridad profesional que estás buscando. PONETE en contacto con un especialista en vehículos blindados hoy mismo.</p>
                <a href="https://api.whatsapp.com/send/?phone=541131532375&text=Hola!&app_absent=0" className="_link">
                  CONTACTANOS POR WHATSAPP
                </a>
              </Col>
              <Col sm={12} md={6}>
                {state.succeeded ? (
                  <div className="sent-success">
                    ¡Muchas gracias!<br />
                    Pronto nos estaremos comunicando con usted.
                  </div>
                ):(
                  <form onSubmit={handleSubmit}>
                    <Input label="Nombre y Apellido *" type="text" name="fullName" required />
                    <ValidationError
                      prefix="Nombre y Apellido"
                      field="fullName"
                      errors={state.errors}
                    />
                    <Input label="Mail *" type="email" name="mail" required />
                    <ValidationError
                      prefix="Email"
                      field="email"
                      errors={state.errors}
                    />
                    <Input label="Teléfono" type="phone" name="phone" />
                    <ValidationError
                      prefix="Message"
                      field="message"
                      errors={state.errors}
                    />
                    <Input label="Mensaje" type="textarea" name="message" />
                    <ValidationError
                      prefix="Mensaje"
                      field="message"
                      errors={state.errors}
                    />
                    <button className="page-footer__send-button" disabled={state.succeeded}>{state.succeeded ? 'ENVIADO' : 'ENVIAR'}</button>
                  </form>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="page-footer__info">
        <div className="_center">
          <Container>
            <Row>
              <Col md={3}>
                <div className="page-footer__copyright">
                  <Logo />
                  <p className="_accent-text">© 2021 Todos los derechos reservados.</p>
                </div>
              </Col>
              <Col md={3} offset={{ md: 1 }}>
                <p className="page-footer__address">
                  Administración, División Técnica y Fábrica.<br />
                  <br />
                  Ruta 2 km 37.500 | CP:B1893<br />
                  El Pato, Berazategui<br />
                  Buenos Aires, Argentina
                </p>
              </Col>
              <Col md={2} offset={{ md: 1 }}>
                <ul className="page-footer__links">
                  <li>
                    <Link to="/">
                      INICIO
                    </Link>
                  </li>
                  <li>
                    <Link to="/nosotros">
                      NOSOTROS
                    </Link>
                  </li>
                  <li>
                    <Link to="/blindajes-360">
                      BLINDADOS
                    </Link></li>
                  <li>
                    <Link to="/industria-de-defensa">
                      SERVICIOS
                    </Link>
                  </li>
                  <li>
                    <Link to="#contacto">
                      CONTACTO
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col md={2}>
                <ul className="page-footer__links">
                  <li><a href="https://www.instagram.com/interservit.ar/">Instagram</a></li>
                  <li><a href="https://www.facebook.com/interservit">Facebook</a></li>
                  <li><a href="https://api.whatsapp.com/send/?phone=541131532375&text=Hola!&app_absent=0">WhatsApp</a></li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
